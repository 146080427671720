import React, { useEffect, Fragment } from "react"

import { Alert, Avatar, Row, Col, Card } from "antd"

import dayjs from "dayjs"

import { useSelector, useDispatch } from "react-redux"

import defaultPatientImage from "../../assets/patient.png"

import {
  getPatientVitals,
  getPatientCaretaker,
  // getPatientMonitoringData,
  getPatientCriticalDevices
} from "../../redux/actions/patientAction"

const PatientDetailInfo = ({ patientDetails }) => {
  const dispatch = useDispatch()

  const { userInfo: patientInfo } = useSelector(state => state.userInfo)
  const { patientVitals } = useSelector(state => state.patientVitals)
  const { patientCaretaker } = useSelector(state => state.patientCaretaker)
  const { patientMonitoringData } = useSelector(state => state.patientMonitoringData)
  const { patientCriticalDevices: criticalDevices, loading: criticalDevicesLoading } = useSelector(
    state => state.patientCriticalDevices
  )

  useEffect(() => {
    if (!patientDetails) return
    dispatch(getPatientVitals(patientDetails?.id))
    dispatch(getPatientCaretaker(patientDetails?.id))
    // dispatch(getPatientMonitoringData(patientDetails?.id))
    if (patientDetails?.critical) dispatch(getPatientCriticalDevices(patientDetails?.id))
  }, [patientDetails])

  const getCriticalDevices = () => (
    <div>
      <h4 className="mb-3 text-xl"> Patient is in Critical Situation</h4>
      <h6 className="text-lg mb-2">
        Critical Devices: {criticalDevicesLoading && !criticalDevices && "Loading..."}
      </h6>
      {(criticalDevices?.length &&
        [...new Set(criticalDevices?.map(({ deviceType }) => deviceType))].map((device, index) => (
          <p key={index} className="capitalize mb-2">
            {Number(index) + 1}. {device}
          </p>
        ))) ||
        (!criticalDevicesLoading && "No Critical Device Found!")}
    </div>
  )

  // const RenderMoniteringProviders = ({ devices }) =>
  //   devices?.map(deviceName =>
  //     patientMonitoringData?.[deviceName]?.map((device, index) => (
  //       <div key={index}>
  //         <span className="font-semibold min-w-[120px] capitalize">
  //           {device?.programs?.programName} Provider :{" "}
  //         </span>
  //         <span className="capitalize">
  //           {device?.providerName}
  //         </span>
  //       </div>
  //     ))
  //   )

  const patientAge = <span>{`${dayjs().diff(dayjs(patientDetails?.dob), "year")} years old`}</span>

  const latestHeightReading = patientVitals
    ? [...patientVitals]
        ?.sort((a, b) => new Date(b.date) - new Date(a.date))
        ?.find(vitalInfo => vitalInfo && vitalInfo?.vitalType === "height")
    : null
  const patientHeight = latestHeightReading ? (
    <Fragment>
      {parseInt(latestHeightReading?.value / 12)
        ?.toString()
        ?.substring(0, 1)}{" "}
      Ft {parseInt(latestHeightReading?.value % 12)} Inch
    </Fragment>
  ) : null

  const latestWeightReading = patientVitals
    ? [...patientVitals]
        ?.sort((a, b) => new Date(b.date) - new Date(a.date))
        ?.find(vitalInfo => vitalInfo && vitalInfo?.vitalType === "weight")
    : null

  return patientInfo === undefined ? (
    <h1>Loading...</h1>
  ) : (
    <Card title="">
      <h4 className="text-2xl">Profile</h4>
      <div className="mt-4 flex items-center gap-3">
        {patientDetails?.image ? (
          <Avatar size={100} src={patientDetails?.image} className="" />
        ) : (
          <Avatar size={100} className="mr-2 flex items-center bg-[#ebf4ff] text-primary">
            <span className="font-bold text-3xl">
              {!patientDetails?.middleName || patientDetails?.middleName === null
                ? `${patientDetails?.firstName?.[0]}${patientDetails?.lastName?.[0]}`?.toUpperCase()
                : `${patientDetails?.firstName?.[0]}${patientDetails?.middleName?.[0]}${patientDetails?.lastName?.[0]}`?.toUpperCase()}
            </span>
          </Avatar>
        )}
        <div>
          <h5 className="mb-1 text-lg capitalize">
            {patientInfo?.firstName?.[0]?.toUpperCase() + patientInfo?.firstName.slice(1)}{" "}
            {patientInfo?.middleName} {patientInfo?.lastName}
          </h5>
          <p className="text-sm text-secondary">
            {patientInfo?.email || "No Email isused for this patient."}
          </p>
        </div>
      </div>
      <div className="mt-4 patient-details-container border p-3 rounded">
        <Row>
          <Col md={12} sm={24}>
            <div className="flex capitalize">
              <span className="font-medium min-w-[100px]">Name : </span>
              {patientInfo?.firstName?.[0]?.toUpperCase() + patientInfo?.firstName.slice(1)}{" "}
              {patientInfo?.middleName}{" "}
              {patientInfo?.lastName?.[0]?.toUpperCase() + patientInfo?.lastName.slice(1)}
            </div>

            <div className="flex">
              <span className="font-medium min-w-[100px]">User Name : </span>
              {patientInfo?.userName}
            </div>

            {patientVitals && latestWeightReading ? (
              <div className="flex">
                <span className="font-medium min-w-[100px]">Weight: </span>
                <Fragment>{latestWeightReading?.value} LB</Fragment>
              </div>
            ) : null}
          </Col>
          <Col md={12} sm={24}>
            <div className="flex">
              <span className="font-medium min-w-[80px]">Email : </span>
              {patientInfo?.email || "No Email isused for this patient."}
            </div>
            <div className="flex">
              <span className="font-medium min-w-[80px]">Age: </span>
              {patientAge}
            </div>
            {patientVitals && patientHeight ? (
              <div className="flex">
                <span className="font-medium min-w-[80px]">Height: </span>
                {patientHeight}
              </div>
            ) : null}
          </Col>
        </Row>
      </div>

      <div className="my-4 patient-details-container border p-3 rounded">
        <Row>
          <Col md={12} sm={24}>
            <p>
              <span className="font-medium">Monitor Types: </span>
              {!patientMonitoringData || Object.keys(patientMonitoringData).length === 0 ? (
                <span>No monitor type are selected for this patient.</span>
              ) : (
                Object.keys(patientMonitoringData).join(", ").toUpperCase()
              )}
            </p>

            <div className="mt-2">
              <h6 className="font-medium mb-2">CareTakers: </h6>
              {patientCaretaker?.length === 0 && (
                <span>No Caretaker selected for this patient.</span>
              )}
              {patientCaretaker?.map((ct, index) => (
                <p key={index}>
                  <span className="font-medium mr-1">{` ${index + 1}. `}</span>
                  <span className="capitalize">{`${ct?.firstName} ${ct?.middleName ?? ""} ${
                    ct?.lastName
                  }`}</span>
                </p>
              ))}
            </div>
          </Col>
          <Col md={12} sm={24}>
            {/* {patientMonitoringData && Object.keys(patientMonitoringData).length ? (
              <RenderMoniteringProviders devices={Object.keys(patientMonitoringData)} />
            ) : null} */}
          </Col>
        </Row>
      </div>
      {patientDetails?.critical ? <Alert type="error" message={getCriticalDevices()} /> : null}
    </Card>
  )
}

export default PatientDetailInfo

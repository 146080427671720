import thunk from "redux-thunk"
import { applyMiddleware, combineReducers, createStore } from "redux"
import {
  ImageReducer,
  passwordUpdateReducer,
  userLoginReducer,
  emailUpdateReducer,
  phoneUpdateReducer,
  validateLoginDetailsReducer,
  profileUpdateReducer,
  googleLoginReducer,
  confirmSignupReducer,
  searchUnIdentifiedReducer,
  postFeedbackReducer,
} from "./reducers/userReducer"
import {
  chronicTypeReducer,
  criticalStatusReducer,
  getChronicConditionReducer,
  getManualTimeReducer,
  getPatientReportReducer,
  HealthBpReducer,
  HealthKpiReducer,
  HealthOxyReducer,
  HealthWmReducer,
  HealthGlucoseReducer,
  patientBpReadingReducer,
  PatientInfoReducer,
  patientOxyReadingReducer,
  PatientLatestReadingReducer,
  patientTimelineReducer,
  patientWmReadingReducer,
  postChronicConditionReducer,
  updateLandlineReducer,
  AddManualTime,
  addConcernReducer,
  getConcernReducer,
  getUserPastConsultationReducer,
  getUserFutureConsultationReducer,
  postCallRequestReducer,
  getUserMonitorTypeReducer,
  getUserBookingReducer,
  postConsultationReducer,
  postCancelConsultationReducer,
  patientCaretakerReducer,
  PatientVitalsReducer,
  PatientMonitoringReducer,
  PatientDiagnosticReducer,
  PatientCriticalDevicesReducer,
  postEmergencyContactDetailReducer,
  UpdatePatientReducer,
  postPatientVitalsReducer,
  outpatientAddressReducer,
  postOutpatientAddressReducer,
  postShippingAddressReducer,
  getShippingAddressReducer,
  updateShippingAddressReducer,
  updateEmergencyContactDetailReducer,
  getEmergencyContactDetailsReducer
} from "./reducers/patientReducer"
import { monitorTypeReducer } from "./reducers/devicesReducer"
import {
  emailChangeReducer,
  emailVerificationReducer,
  numberChangeReducer
} from "./reducers/utilReducer"
import {
  getCcmReducer,
  postCCMReducer,
  getCcmCarePlanReducer,
  getFacilityAddressReducer,
  getUserSearchReducer,
  getFilterUsersReducer,
  getUsersForCcmFollowUpReducer
} from "./reducers/ccmReducer"
import {
  getRpmReducer,
  postRpmReducer,
  getRpmCarePlanReducer,
  getUsersForRpmFollowUpReducer,
  postRpmTriageReducer,
  getRpmDeviceReducer
} from "./reducers/rpmReducer"

import { getThemeReducer } from "./reducers/themeReducer"

import {
  postRTMPainManagementDataReducer,
  getRTMPainManagementDataReducer,
  getRTMPainManagementReadingReducer,
  postRTMAddictionMedicineDataReducer,
  getRTMAddictionMedicineDataReducer,
  getRTMAddictionMedicineReadingReducer,
  postRTMMentalHealthDataReducer,
  getRTMMentalHealthDataReducer,
  getRTMMentalHealthReadingReducer
} from "./reducers/rtmReducers"
import {
  getPatientBpCustomParameterReducer,
  getPatientGlucoCustomParameterReducer,
  getPatientOxiCustomParameterReducer
} from "./reducers/customParameterReducer";
import { getLatestPcmPlanReducer } from "./reducers/pcmReducer"
import { 
  updateSelfOnboardedPatientReducer,
  getSelfOnboardedPatientReducer, 
  getEnrolSpGeneratedCodeReducer
} from "./reducers/selfOnboardPatientReducer"
import {
  getEquipmentStatusReducer,
  postEquipmentFunctioningStatusReducer,
  postEquipmentStatusMethodReducer
} from "./reducers/equipmentStatusReducer"

const reducer = combineReducers({
  userInfo: userLoginReducer,
  searchUnIdentified: searchUnIdentifiedReducer,
  googleLogin: googleLoginReducer,
  imageUploader: ImageReducer,
  themeConfig: getThemeReducer,
  passwordUpdate: passwordUpdateReducer,
  userEmailUpdate: emailUpdateReducer,
  userPhoneUpdate: phoneUpdateReducer,
  profileUpdate: profileUpdateReducer,
  validateLoginDetails: validateLoginDetailsReducer,
  confirmSignup: confirmSignupReducer,
  // dashboardKPI: dashboardKPIReducer,
  // filterdashboardKPI: filterDashboardReducer,
  // criticalPatient: criticalPatientReducer,
  manualList: getManualTimeReducer,
  chronicConcern: getChronicConditionReducer,
  criticalStatus: criticalStatusReducer,
  // PATINET_DATA
  patientCriticalDevices: PatientCriticalDevicesReducer,
  patientVitals: PatientVitalsReducer,
  postPatientVitals:postPatientVitalsReducer,
  patientDiagnosticData: PatientDiagnosticReducer,
  patientMonitoringData: PatientMonitoringReducer,
  patientCaretaker: patientCaretakerReducer,
  patientInfo: PatientInfoReducer,
  outpatientAddress: outpatientAddressReducer,
  postOutpatientAddress: postOutpatientAddressReducer,
  patientReport: getPatientReportReducer,
  healthKpi: HealthKpiReducer,
  healthWm: HealthWmReducer,
  healthBp: HealthBpReducer,
  healthOxy: HealthOxyReducer,
  healthGlucose: HealthGlucoseReducer,
  patientLatestReading: PatientLatestReadingReducer,
  patientTimeline: patientTimelineReducer,
  weightMachineReading: patientWmReadingReducer,
  oxygenMeterReading: patientOxyReadingReducer,
  bloodPressureReading: patientBpReadingReducer,
  emergencyContactDetail : postEmergencyContactDetailReducer,
  updateEmergencyContactDetails: updateEmergencyContactDetailReducer,
  getEmergencyContactDetails: getEmergencyContactDetailsReducer,
  postShippingAddress: postShippingAddressReducer,
  shippingAddress : getShippingAddressReducer,
  updateShippingAddress: updateShippingAddressReducer,
  // equipment status
  getEquipmentStatus : getEquipmentStatusReducer,
  postEquipmentFunctioning : postEquipmentFunctioningStatusReducer,
  postEquipmentMethodOption : postEquipmentStatusMethodReducer,
  // addPatient: AddPatientReducer,
  updatePatient: UpdatePatientReducer,
  chronicDeviceType: chronicTypeReducer,
  chronicConditionStatus: postChronicConditionReducer,
  addManualTime: AddManualTime,
  updateLandline: updateLandlineReducer,
  patientConcern: addConcernReducer,
  patientConcernData: getConcernReducer,
  userPastConsultation: getUserPastConsultationReducer,
  userFutureConsultation: getUserFutureConsultationReducer,
  userCallRequest: postCallRequestReducer,
  getMonitorTypes: getUserMonitorTypeReducer,
  getBooking: getUserBookingReducer,
  addConsultation: postConsultationReducer,
  cancelConsultation: postCancelConsultationReducer,
  //selfonboarded
  updateSelfOnboaredePatient: updateSelfOnboardedPatientReducer,
  getSelfOnboardedPatient: getSelfOnboardedPatientReducer,
  getEnrolSpGeneratedCode: getEnrolSpGeneratedCodeReducer,
  // PROVIDER_DETAILS
  // providerList: providerReducer,
  // providerKPI: providerKPIReducer,
  // providerPatient: providerPatientReducer,
  // addProvider: addProviderReducer,
  // providerNPI: searchProviderByNPI,
  // singleProvider: getSingleProviderReducer,
  // CATETAKER_DATA
  // caretakerCard: caretakerCardReducer,
  // caretakerList: caretakerListReducer,
  // singleCaretaker: singleCaretakerReducer,
  // caretakerPatient: caretakerPatientReducer,
  // addCaretaker: addCaretakerReducer,
  // careTypes: getCareTypesReducer,
  // cateTakerManualtime: updateCaretakerManualTimeReducer,
  // devices details
  monitorTypeList: monitorTypeReducer,

  // utils
  emailVerification: emailVerificationReducer,
  emailUpdate: emailChangeReducer,
  numberUpdate: numberChangeReducer,
  // CCM
  ccmList: getCcmReducer,
  postCCMData: postCCMReducer,
  ccmPatientCarePlan: getCcmCarePlanReducer,
  facilityAddress: getFacilityAddressReducer,
  filterSearch: getUserSearchReducer,
  filter: getFilterUsersReducer,
  ccmFollowUpUsers: getUsersForCcmFollowUpReducer,
  //pcm
  getLatestPcmCareplan: getLatestPcmPlanReducer,
  // RPM
  rpmList: getRpmReducer,
  postRPMData: postRpmReducer,
  rpmPatientCarePlan: getRpmCarePlanReducer,
  rpmFollowUpUsers: getUsersForRpmFollowUpReducer,
  rpmTriageStore: postRpmTriageReducer,
  rpmDevices: getRpmDeviceReducer,
  // RTM - Pain Management
  postRTMPainManagementData: postRTMPainManagementDataReducer,
  getRTMPainManagementData: getRTMPainManagementDataReducer,
  getRTMPainManagementReading: getRTMPainManagementReadingReducer,
  // RTM - Addiction Medicine
  postRTMAddictionMedicineData: postRTMAddictionMedicineDataReducer,
  getRTMAddictionMedicineData: getRTMAddictionMedicineDataReducer,
  getRTMAddictionMedicineReading: getRTMAddictionMedicineReadingReducer,
  // RTM - Mental Health
  postRTMMentalHealthData: postRTMMentalHealthDataReducer,
  getRTMMentalHealthData: getRTMMentalHealthDataReducer,
  getRTMMentalHealthReading: getRTMMentalHealthReadingReducer,
  // custom parameter
  getPatientBpCustomParameter: getPatientBpCustomParameterReducer,
  getPatientOxiCustomParameter: getPatientOxiCustomParameterReducer,
  getPatientGlucoCustomParameter: getPatientGlucoCustomParameterReducer,
  // feedback
  postFeedback: postFeedbackReducer,
})

const middleware = [thunk]

// const store = createStore(reducer, composeWithDevTools(applyMiddleware(...middleware)));
const store = createStore(reducer, applyMiddleware(...middleware))

export default store
